@import 'variables';

.md-sidemenu {
  md-divider {
    width: 100%;
    margin: 8px 0;

    + .md-subheader {
      margin-top: -8px;
    }
  }

  .md-button {
    width: 100%;
    height: 48px;
    min-height: 48px;
    margin: 0;
    padding: 0 16px;
    border-radius: 0;
    background-color: #fff;
    text-align: left;
    text-rendering: optimizeLegibility;
    text-transform: none;

    &:hover {
      background-color: #eee;
    }

    .md-ripple-container {
      border-radius: 0 !important;
    }
  }

  .md-subheader {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
  }
}

md-sidemenu-button {
  width: 100%;
  display: flex;
}

.md-sidemenu-button {
  md-icon:first-child {
    max-width: 24px;
    margin-right: 32px;
  }

  span {
    flex: 1;
  }
}

.md-sidemenu-content {
  width: 100%;
  min-height: 48px;
  overflow: hidden;
}

.md-sidemenu-toggle {
  &.md-active md-icon:last-child {
    transform: rotate(180deg) translateZ(0);
  }

  .md-sidemenu & {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-flow: column;
    z-index: 1;
    transition: .4s cubic-bezier(.25, .8, .25, 1);
  }

  md-icon {
    transition: .4s transform cubic-bezier(.25, .8, .25, 1);

    &:first-child {
      max-width: 24px;
      margin-right: 32px;
    }
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.md-sidemenu-wrapper {
  overflow: hidden;
  opacity: 0;
  transform: translate3D(0, 0, 0);
  transition: .4s cubic-bezier(.55, 0, .55, .2);
  transition-property: transform, opacity, margin, background;

  &.md-active {
    margin-top: 0 !important;
    opacity: 1;
    transition-timing-function: cubic-bezier(.25, .8, .25, 1);
  }

  &.md-sidemenu-wrapper-icons .md-button {
    padding-left: 72px;
  }

  .md-button {
    padding-left: 32px;
  }
}
